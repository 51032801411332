import { canShowPDPSystem } from '@/utils/canShowPDPSystem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { returnSpecificImageUrl } from '../../../utils/returnSpecificImageUrl';
// eslint-disable-next-line no-undef
const { motion } = require('framer-motion');

const slideMotionFirst = {
  rest: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  hover: {
    x: '-100%',
    opacity: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const slideMotionSecond = {
  rest: {
    x: '100%',
    opacity: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  hover: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const dissolveMotionFirst = {
  rest: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
  hover: {
    x: 0,
    opacity: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const dissolveMotionSecond = {
  rest: {
    x: 0,
    opacity: 0,
    transition: {
      duration: 0.8,
    },
  },
  hover: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};

const defaultImageUrl = 'https://cdn.dotpe.in/longtail/store-items/default_image.png';

const ItemImage = (props) => {
  const [animationProperties, setAnimationProperties] = useState({
    first: null,
    second: null,
  });
  const [imgArr, setImgArr] = useState([]);
  const [arHeight, setArHeight] = useState(100);

  const { theme } = useSelector((state) => state.storeReducer.store);

  useEffect(() => {
    if (props?.categoryConfig?.animation) {
      switch (props?.categoryConfig?.animation) {
        case 'slide': {
          setAnimationProperties({
            first: slideMotionFirst,
            second: slideMotionSecond,
          });
          break;
        }
        case 'pop': {
          setAnimationProperties({
            first: dissolveMotionFirst,
            second: dissolveMotionSecond,
          });
          break;
        }
        default:
          setAnimationProperties({
            first: null,
            second: null,
          });
          break;
      }
    }
  }, [props?.categoryConfig?.animation]);

  useEffect(() => {
    if (props?.productData?.images?.length > 2) {
      const images = props?.productData?.images;
      // if image length is more than 2
      // reduce array length down to 2
      images.length = 2;
      setImgArr(images);
    } else {
      setImgArr(props?.productData?.images);
    }
  }, [props?.productData?.images]);

  useEffect(() => {
    if (props?.categoryConfig?.aspect_ratio) {
      setArHeight(props?.categoryConfig?.aspect_ratio);
    } else if (theme?.product_aspect_ratio) {
      setArHeight(10000 / theme?.product_aspect_ratio);
    } else {
      setArHeight(100);
    }
  }, [props?.categoryConfig?.aspect_ratio]);

  const checkForMediaType = (image) => {
    if (image.media_type === 2) return defaultImageUrl;
    else return returnSpecificImageUrl({ theme: theme, item: image });
  };

  const singleImageRender = (image) => {
    return (
      <div
        className="image-wrap"
        style={{
          paddingTop: `${arHeight}%`,
        }}
      >
        <div
          className="collection-image"
          style={{
            paddingTop: `${arHeight}%`,
            backgroundImage: `url(${checkForMediaType(image)})`,
          }}
        />
      </div>
    );
  };

  const multiImageRender = (imgArr) => {
    return (
      <motion.section
        className="image-wrap"
        initial="rest"
        whileHover="hover"
        animate="rest"
        style={{
          paddingTop: `${arHeight}%`,
        }}
      >
        {!!imgArr?.length &&
          imgArr.map((image, index) => {
            return (
              <motion.div
                key={index}
                variants={
                  index === 0 ? animationProperties.first : animationProperties.second
                }
                className="collection-image"
                style={{
                  paddingTop: `${arHeight}%`,
                  backgroundImage: `url(${checkForMediaType(image)})`,
                }}
              ></motion.div>
            );
          })}
      </motion.section>
    );
  };

  return (
    <div className="relative w-100 framer-card">
      {imgArr?.length === 1 || !imgArr
        ? singleImageRender(imgArr ? imgArr[0] : { image_url: defaultImageUrl })
        : multiImageRender(imgArr)}
      <div className="banner-type">
        {canShowPDPSystem(theme?.theme_class) ? 'View Product' : 'Quick View'}
      </div>
    </div>
  );
};

export default ItemImage;
